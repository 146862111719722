<template>
<section class="w-full">
    <div class="container mx-auto px-8 transform translate-y-12 lg:translate-y-24">
        <div class="lg:w-8/12 mx-auto text-center">
            <h3 class="text-2xl lg:text-4xl font-body font-semibold pb-5" v-html="$t('contact.title')"></h3>
            <h4 class="font-body font-bold text-lg lg:text-2xl">Email</h4>
            <a href="mailto:versekos.asociacija@gmail.com" class="block pb-5 text-xl lg:text-2xl text-blue-800 hover:text-red-600">versekos.asociacija@gmail.com</a>
            <h4 class="font-body font-bold lg:text-2xl" v-html="$t('contact.phone')"></h4>
            <a href="tel:+37061815288" class="block pb-5 text-xl lg:text-2xl text-blue-800 hover:text-red-600">+370 618-15-288</a>
            <h4 class="font-body font-bold text-lg lg:text-2xl pt-2" v-html="$t('contact.address')"></h4>
            <p class="text-xl lg:text-2xl pb-5">
                Mokyklos g. 4 <br />
                Matuizų k. Šalčininkų raj. sav., LT 1718
            </p>
            <p class="text-lg lg:text-2xl pb-5">
                <span class="font-body font-bold" v-html="$t('contact.id')"></span>:
                303359545
            </p>

            <p class="text-lg lg:text-2xl pb-5">
                <span class="font-body font-bold" v-html="$t('contact.bank')"></span>:
                LT844010051002112708 <br />
                <span class="text-lg lg:text-xl">Luminor Bank </span>
            </p>

            <h4 class="font-body font-bold text-xl lg:text-2xl" v-html="$t('contact.preziname')"></h4>
            <h5 class="font-body lg:text-lg pb-3" v-html="$t('contact.prezidentas')"></h5>
            <img src="@/assets/img/prezes.svg" :alt="$t('contact.preziname')" class="mx-auto h-40 lg:h-auto" />
        </div>
    </div>
    <div>
        <img src="@/assets/img/mapa.jpg" :alt="$t('title')" class="mx-auto" />
    </div>
</section>
</template>

<script>
export default {
    name: "Contact",
};
</script>
